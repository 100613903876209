import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { SSO_INTERNAL, SSO_EXTERNAL,SSO_INTERNAL_USER_INFO, SSO_EXTERNAL_USER_INFO, SECURITY_INITIAL_PAGE, API_TMC_URL, API_UF_URL, API_UTM_URL } from 'src/app/config/globals-constants';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }
    private INTERNAL_URL = SSO_INTERNAL;
    private EXTERNAL_URL = SSO_EXTERNAL;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {              
        const token = this.authenticationService.token;        
        if (req.url != this.INTERNAL_URL
            && req.url != this.EXTERNAL_URL
            && req.url != SSO_INTERNAL_USER_INFO
            && req.url != SSO_EXTERNAL_USER_INFO
            && req.url != API_UF_URL
            && req.url != API_UTM_URL
            && req.url != API_TMC_URL
            && req.url != SECURITY_INITIAL_PAGE
            && token) {
            this.authenticationService.refreshToken(token, true);
        }
        return next.handle(req);
    }
}