import { Injectable } from '@angular/core';
import { LoginModel } from '../../models/login.model';
import { TokenModel } from '../../models/token.model';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {map, catchError, take} from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SSO_EXTERNAL, SSO_INTERNAL } from 'src/app/config/globals-constants';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private INTERNAL_URL = SSO_INTERNAL;
  private EXTERNAL_URL = SSO_EXTERNAL;
  private API_KEY: string = environment.apiKey;

  private loggedIn = new BehaviorSubject<boolean>(false);
  private loginHour = new Date();
  public jwtHelper = new JwtHelperService();

  constructor(protected httpClient: HttpClient,
    private router: Router) {
  }

  get isLogged(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  login(login: LoginModel): Observable<void> {
    const body = new HttpParams().set('grant_type', 'password')
      .set('username', login.username)
      .set('password', login.password)
      .set('client_id', 'hs-site-accounting');

    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded;"
      })
    };

    this.loginHour = new Date();
    if (login.internalUser) {
      return this.httpClient.post<TokenModel>(this.INTERNAL_URL, body, options).pipe(
        map((response: TokenModel) => {
          localStorage.setItem('internalUser', 'true');
          localStorage.setItem('username', login?.username);
          localStorage.setItem('accessToken', JSON.stringify(response));
          localStorage.setItem('firstLoginHour', this.loginHour?.toString());
          localStorage.setItem('loginHour',this.loginHour?.toString());
          this.loggedIn.next(true);
          //return response;
        }),
        catchError((error) => this.handlerError(error))
      );
    } else {
      return this.httpClient.post<TokenModel>(this.EXTERNAL_URL, body, options).pipe(
        map((response: TokenModel) => {
          localStorage.setItem('internalUser', 'false');
          localStorage.setItem('username', login.username);
          localStorage.setItem('accessToken', JSON.stringify(response));
          localStorage.setItem('firstLoginHour', this.loginHour?.toString());
          localStorage.setItem('loginHour',this.loginHour?.toString());
          this.loggedIn.next(true);
          localStorage.loginHour = this.loginHour;          
          //return response;
        }),
        catchError((error) => this.handlerError(error))
      );
    }
  }

  getRefreshHour() {
    const token = this.token;
    let refreshDate: Date;
    if (token) {
      const numberOfMlSeconds = new Date(Date.parse(localStorage.loginHour)).getTime();
      const addRefreshMlSeconds = (token.expires_in) * 1000;
      refreshDate = new Date(numberOfMlSeconds + addRefreshMlSeconds);
    }
    return refreshDate;
  }

  getFirstLoginRefreshHour() {
    const token = this.token;
    let refreshDate: Date;
    if (token) {
      const numberOfMlSeconds = new Date(Date.parse(localStorage.firstLoginHour)).getTime();
      const addRefreshMlSeconds = 1800000;
      refreshDate = new Date(numberOfMlSeconds + addRefreshMlSeconds);
    }
    return refreshDate;
  }

  refreshToken(token: TokenModel, expiredInitialTime: boolean) {    
    this.loginHour = new Date();
    const body = new HttpParams().set('refresh_token', token.refresh_token)
      .set('client_id', 'hs-site-accounting')
      .set('grant_type', 'refresh_token');

    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded;"
      })
    };
    
    if (localStorage.internalUser === 'true') {
      return this.httpClient.post<TokenModel>(this.INTERNAL_URL, body, options).subscribe(response => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('loginHour');
        localStorage.setItem('accessToken', JSON.stringify(response));
        this.loggedIn.next(true);
        localStorage.loginHour = this.loginHour;
        if(expiredInitialTime) {
          localStorage.removeItem('firstLoginHour');
          localStorage.firstLoginHour = this.loginHour;
        }

        return response;
      });
    } else {
      return this.httpClient.post<TokenModel>(this.EXTERNAL_URL, body, options).subscribe(response => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('loginHour');
        localStorage.setItem('accessToken', JSON.stringify(response));
        this.loggedIn.next(true);
        localStorage.loginHour = this.loginHour;
        if(expiredInitialTime) {
          localStorage.removeItem('firstLoginHour');
          localStorage.firstLoginHour = this.loginHour;
        }

        return response;
      });
    }
  }

  logout(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loginHour');
    localStorage.removeItem('internalUser');
    localStorage.removeItem('executiveId');
    localStorage.removeItem('comertialExecutive');
    localStorage.removeItem('riskExecutive');
    localStorage.removeItem('operationsExecutive');
    localStorage.removeItem('formalizer');
    localStorage.removeItem('supervisor');
    localStorage.removeItem('username');
    localStorage.removeItem('firstLoginHour');
    this.loggedIn.next(false);
    this.router.navigate(['/account/login-2']);
  }

  isExpired(accessToken: TokenModel): boolean {
    return this.jwtHelper.isTokenExpired(accessToken.access_token) as boolean;
  }

  public get token(): TokenModel {
    return JSON.parse(localStorage.getItem('accessToken'));
  }

  handlerError(error): Observable<never> {
    let errorMessage = 'Ha ocurrido un error';
    if (error) {
      errorMessage = `Error: code ${error?.message}`;
    }
    return throwError(errorMessage);
  }

  get headers(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': this.API_KEY
      })
    };
  }

  get headersSimulationService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.simulatorClientId
      })
    };
  }

  get headersProfileService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.profileClientId
      })
    };
  }

  get headersSolicitationService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.solicitationClientId
      })
    };
  }

  get headersInsurancePolicyService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.insurancePolicyClientId
      })
    };
  }

  get headersJudicialLiquidationService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.judicialLiquidationClientId
      })
    };
  }

  get headersMutualService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.mutualClientId
      })
    };
  }

  get headersDevelopmentTableService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.developmentTableClientId
      })
    };
  }

  get headersDividendService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.dividendClientId
      })
    };
  }

  get headersFinancialPortabilityService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.financialPortabilityClientId
      })
    };
  }

  get headersHubspotService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.hubspotClientId
      })
    };
  }

  get headersTmcService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.tmcClientId
      })
    };
  }

  get headersWsKtaService(): { headers: HttpHeaders } {
    // console.log('AuthenticationService: headers() >> new HttpHeaders')
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Content-Type': 'application/json',
        'Authorization': (accessToken !== null && accessToken !== undefined) ? accessToken : '',
        'X-IBM-Client-Id': environment.wsKtaClientId
      })
    };
  }
}
