import { Component, OnInit, AfterViewInit, Inject, OnDestroy, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { EventService } from '../../core/services/event.service';
import { AuthenticationService } from '../../authentication/services/authentication.service';

import { DOCUMENT } from '@angular/common';

import * as moment from 'moment';
import 'moment-duration-format';
import { Subscription, timer } from 'rxjs';
import Swal from 'sweetalert2';
import { ProfileRestService } from 'src/app/services/rest/profile-rest.service';
import { AccessPlus } from 'src/app/models/access-plus.model';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss']
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {

  element: any;
  configData: any;
  userExternal: boolean;
  menuItems: AccessPlus[] = [];
  menu: any;

  session = '';
  source = timer(0, 1000);
  clock: Subscription;
  userName: string;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private eventService: EventService,
    public _cookiesService: CookieService,
    public authService: AuthenticationService,
    public profileService: ProfileRestService
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    });
  }

  ngOnInit(): void {
    this.element = document.documentElement;
    this.getUserName();
    this.initialize();
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.clock = this.source.subscribe(t => {
      this.getSessionTime();
    });
  }


  /**
   * Logout the user
   */
  logout(): void {
    Swal.close();
    this.authService.logout();
    this.router.navigate(['./']);
  }

  /**
   * On menu click
   */
  onMenuClick(event: any): boolean {
    const nextEl: Element = event.target.nextElementSibling;
    if (nextEl) {
      const parentEl: Element = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove('show');
      }
      nextEl.classList.toggle('show');
    }
    return false;
  }

  ngAfterViewInit(): void {
    this.activateMenu();
  }


  /**
   * remove active and mm-active class
   */
  _removeAllClass(className: string): void {
    const els: HTMLCollectionOf<Element> = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar(): void {
    const element: HTMLElement = document.getElementById('topnav-menu-content');
    element.classList.toggle('show');
  }

  /**
   * Activates the menu
   */
  private activateMenu(): void {

    const resetParent: any = (el: any) => {
      const parent: HTMLElement = el.parentElement;
      if (parent) {
        parent.classList.remove('active');
        const parent2: HTMLElement = parent.parentElement;
        this._removeAllClass('mm-active');
        this._removeAllClass('mm-show');
        if (parent2) {
          parent2.classList.remove('active');
          const parent3: HTMLElement = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4: HTMLElement = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove('active');
              const parent5: HTMLElement = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('active');
                const menuelement: HTMLElement = document.getElementById('topnav-menu-content');
                if (menuelement !== null) {
                  if (menuelement.classList.contains('show')) {
                    document
                      .getElementById('topnav-menu-content')
                      .classList.remove('show');
                  }
                }
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links: HTMLCollectionOf<Element> = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem: Element = null;
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    for (let i = 0; i < links.length; i++) {
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      const parent: HTMLElement = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2: HTMLElement = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3: HTMLElement = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4: HTMLElement = parent3.parentElement;
            if (parent4) {
              parent4.classList.add('active');
              const parent5: HTMLElement = parent4.parentElement;
              if (parent5) {
                parent5.classList.add('active');
              }
            }
          }
        }
      }
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked(): void {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * Fullscreen method
   */
  fullscreen(): void {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Topbar light
   */
  topbarLight(): void {
    document.body.setAttribute('data-topbar', 'light');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-layout-scrollable');
  }

  /**
   * Set boxed width
   */
  boxedWidth(): void {
    document.body.setAttribute('data-layout-size', 'boxed');
    document.body.setAttribute('data-topbar', 'dark');
    document.body.removeAttribute('data-layout-scrollable');
  }

  /**
   * Colored header
   */
  coloredHeader(): void {
    document.body.setAttribute('data-topbar', 'colored');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-layout-scrollable');
  }

  scrollable(): void {
    document.body.removeAttribute('data-layout-size');
    document.body.setAttribute('data-layout-scrollable', 'true');
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string): void {
    this.eventService.broadcast('changeLayout', layout);
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menu = this.profileService.getMenuAccesses().subscribe(menu => {
      this.menuItems = menu
    });
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: AccessPlus): boolean {
    return item.subAccesses && item.subAccesses.length > 0;
  }

  trackByFn(index: number): number { return index; }

  getSessionTime() {
    const actualTime = moment(new Date());
    const refreshHour = moment(this.authService.getRefreshHour());
    const newRefreshHour = moment(this.authService.getFirstLoginRefreshHour());
    
    const diff = newRefreshHour.diff(actualTime);
    const expirationTime = refreshHour.diff(actualTime);
    
    const duration = moment.duration(diff);
    this.session = duration.format("hh:mm:ss");
    
    if(Math.floor(expirationTime / 1000) == 10) {
      const token = this.authService.token;
      this.authService.refreshToken(token, false);
    } else if (Math.floor(diff / 1000) == 10) {
      Swal.fire({
        // title: 'Are you sure?',
        text: "¿Quiere mantener la sesion iniciada?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si',
      }).then((result) => {
        if (result.isConfirmed) {
          const token = this.authService.token;
          this.authService.refreshToken(token, true);
        } else if (result.isDismissed) {
          Swal.close();
          this.clock.unsubscribe();
          this.authService.logout();
        }
      })
    } else if (diff <= 0) {
      Swal.close();
      this.authService.logout();
      this.clock.unsubscribe();
    }
  }

  getUserName() {
    this.userName = localStorage.getItem('username');
  }
}
