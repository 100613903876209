export const environment = {
    production: false,
    defaultauth: '',
    // host: 'https://190.243.30.34:5200/hs-qa/hipotecarios',
    host: 'https://api-qa.securityprincipal.cl:5200/hs-qa/hipotecarios',
    apiKey: '4f9d1d29-d215-4fff-aeb0-a5a47e75af5a',
    homeRoute: 'https://hs-as-acc-front-qa.azurewebsites.net/auth/login',
    loginErrorRoute: 'Http failure response for https://qa-sso.security.cl:8543/auth/realms/HS-Internals/protocol/openid-connect/token: 401 OK',
    cmfErrorRoute: 'Http failure response for https://api.sbif.cl/api-sbifv3/recursos_api/utm?apikey=eeca503d1a029d14951c5af43ca9302729daf297&formato=json: 404 No Encontrado',
    basePathSimulatorApi:'/Simulator',
    basePathSolicitationApi:'/Solicitation',
    basePathInsurancePolicyApi:'/Policy',
    basePathDevelopmentTableApi:'/DevelopmentTable',
    basePathJudicialLiquidationApi:'/Liquidation',
    basePathMutualApi:'/Mutual',
    basePathProfileApi:'/Profile',
    SSOBaseUrl: 'https://qa-sso.security.cl:8543/auth/realms/',
    profileClientId: 'e3ca2ba5-3125-4585-a058-2b5b07c322ac',
    simulatorClientId: '316fc230-baee-48d8-8f41-41321c3e870b',
    solicitationClientId: '6091db70-d35e-48c7-82d0-2d2c180458eb',
    insurancePolicyClientId: '06bbcb8b-e51a-477c-94f8-817fbcd7fe2f',
    judicialLiquidationClientId: '5a271b71-db4c-48a2-b9db-2ad19d23a71d',
    mutualClientId: '862b5d7f-c2ef-4148-bc75-9cfe8b2270f0',
    developmentTableClientId: '92ad08f8-6a4d-4f43-9256-a2917bf6b90d',
    dividendClientId: '1337579f-e4a5-47bb-a776-815d9940da79',
    financialPortabilityClientId: '565e42a7-a3ed-4f6f-8c0e-9880c8a02f27',
    hubspotClientId: 'a2c3182d-31c5-4243-abae-a4b63a4704db',
    tmcClientId: 'd4ffa837-b7be-44f6-8f13-fb260a3eca2c',
    wsKtaClientId: 'eab27afc-8b29-4b38-821e-7d24d3335f20'
  };